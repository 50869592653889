import React from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import ChangePassword from "../../../graphql/mutations/ChangePassword";
import { doLogout } from "../../../reducers/app";
import { notification, themes } from "../../../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store";

interface Props {
    handleClose: () => void;
}

const schema = yup
    .object({
        oldPassword: yup.string()
            .required("Please enter old password").matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
            ),
        newPassword: yup.string()
            .required("Please enter new password").matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
            ),
        confirmNewPass: yup.string().required("Please confirm your password")
            .oneOf([yup.ref('newPassword')], "Passwords don't match.")
    })
    .required()

const ChangePasswordForm: React.FC<Props> = ({ handleClose }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(schema) });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [changePassword] = useMutation(ChangePassword);

    const onFormSubmit = (v: any) => {
        if (v.newPassword !== v.confirmNewPass) {
            return;
        }
        delete v.confirmNewPass;

        changePassword({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                if (data && data.changePassword && data.changePassword.success) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    dispatch(doLogout());
                    notification({ message: 'Logout successfully!' });
                    handleClose();
                    navigate('/login');
                }
            }, 
            onError: (err) => {
                notification({ message: err.message, type: 'danger' })
            }
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Change Password Form
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Old Password"
                    placeholder="Enter Old Password"
                    customId="oldPassword"
                    type="password"
                    register={register}
                    errors={errors?.oldPassword?.message}
                />
                <TextField
                    label="New Password"
                    placeholder="Enter New Password"
                    customId="newPassword"
                    type="password"
                    register={register}
                    errors={errors?.newPassword?.message}
                />
                <TextField
                    label="Confirm New Password"
                    placeholder="Enter Confirm Password"
                    customId="confirmNewPass"
                    type="password"
                    register={register}
                    errors={errors?.confirmNewPass?.message}
                />
                <SubmitButton label="Change Password" />
            </form>
        </div>
    )
}

export default ChangePasswordForm