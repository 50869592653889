import React from 'react';
import ClearIcon from "@mui/icons-material/Clear";

interface Props{
    file: any;
    setFile: (x:any) => void
}

const DragAndDrop:React.FC<Props> = ({file, setFile}) => {

    const handleDrop = (event:any) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        console.log('droppedFile', droppedFile);
        
        setFile(droppedFile);
    };

    const handleDragOver = (event:any) => {
        event.preventDefault();
    };

    const handleFileSelect = (event:any) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer', position: 'relative' }}
        >   
            {!file && <div>
                <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id='file' />
                <label htmlFor='file' role='button'>Drag and drop your file here, or click to select file.</label>
            </div>}
            {
               file && <ShowSelectedFile setFile={setFile} selectedFiles={file}  /> 
            }
        </div>
    );
};

interface fileProps{
    selectedFiles: any
    setFile: (x:any) => void
}

const ShowSelectedFile:React.FC<fileProps> = ({selectedFiles, setFile}) => {
    return (
        <div>
        <button className='position-absolute reset-logo' onClick={() => setFile(null)}>
          <ClearIcon />
        </button>
        <div>
          <img
            src={
              selectedFiles ? typeof selectedFiles === 'string' ? 
              `${process.env.REACT_APP_BACKEND_BASE_URL}/${selectedFiles}` :
                URL.createObjectURL(selectedFiles) : ""
            }
            alt="logo"
            width={200}
            height={150}
            style={{ margin: "auto", display: "block" }}
          />
        </div>
      </div>
    )
}

export default DragAndDrop;
