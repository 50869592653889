import { gql } from "@apollo/client";

export default gql`
    mutation Login($input: LoginInput!){
        login(input: $input){
            result{   
                token
                refreshToken
                user{
                    firstName
                    lastName
                    email
                    isActive
                    isSuperuser
                    isStaff
                    userId
                    username
                    organization
                    dob
                    joiningDate
                    mobile
                }
            }
        }
    }
`