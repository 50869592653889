import { gql } from "@apollo/client";

export default gql `
query GetUserRoles($where: JSONString, $limit: Int, $offset: Int, $order: String){
  roles(where: $where, limit: $limit, offset: $offset, order: $order){
    id
    firstName
    lastName
    organization
    userRole
    userId
    roleId
  }
}
`