import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";

import List from "./List";
import GetOrganizations from "../../graphql/queries/GetOrganizations";
import DeleteOrganization from "../../graphql/mutations/DeleteOrganization";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import EditOrganizationForm from "../forms/organizations/EditOrganization";
import { notification } from "../../service";
import AddButton from "../common/AddButton";
import QrCodeComponent from "../common/QRCode";
import type { Direction } from "../../service/types";
import { RootState } from "../../reducers/store";
import CustomAlert from "../common/CustomAlert";

const OrganizationList:React.FC = () => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("name");
  const [dir, setDir] = useState<Direction>("asc");
  const [editId, setEditId] = useState<number|null>(null);
  const { theme } = useSelector((state:RootState) => state.sidebar)
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteOrganization] = useMutation(DeleteOrganization);

  const handleSortingChange = useCallback((columnDir:any, orderBy:any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
  }, []);

  const { data, loading, error } = useQuery(GetOrganizations, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        search,
      },
      order: `${order} ${dir}`,
    },
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({
        message: error.message,
        type: "danger",
        title: "Unauthorize",
      });
    }
  }, [loading, error]);

  const handleEdit = (id:number) => {
    setEditId(id);
    setShowEditModal(true);
  };

  const onDelete = (id: number) => {
    deleteOrganization({
      variables: {
        id,
      },
      onCompleted: (data) => {
        if (data && data?.deleteOrganization && data?.deleteOrganization?.success)
          notification({
            message: "Organization deleted successfully!",
            type: "success",
          });
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetOrganizations"],
    })
  }

  const handleDestroy = (id:number) => {
    confirmAlert({
      customUI: ({onClose}) => 
        <CustomAlert
         id={id}
         onClose={onClose}
         callback={onDelete}
         message="Are you sure you want to delete this organization"
         theme={theme}
        />
    })
  }

  const columns = [
    {
      name: "name",
      label: "Organization Name",
      width: 100,
    },
    {
      name: "slug",
      label: "Slug",
      align: "center",
      width: 100,
    },
    {
      name: "orgEmail",
      label: "Email",
      align: "center",
      width: 100,
    },
    {
      name: "orgPhone",
      label: "Mobile Number",
      align: "center",
      width: 100,
    },
    {
      name: "logo",
      label: "Logo",
      align: "center",
    },
    {
      name: "qrcode",
      label: "QR Code",
      align: "center",
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const rows =
    !loading && data && data.organizations 
      ? (data.organizations.edges || []).map((node:any) => {
        const { node : row } = node;
        
          const action = (
            <TableActionCell
              hideEdit={false}
              editLabel="Edit Organization"
              onEdit={handleEdit}
              hideDestroy={false}
              tableRow={{ rowId: row.orgId }}
              onDestroy={handleDestroy}
            />
          );
          const logo = (
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${row.logo}`}
              width={"50%"}
              style={{ borderRadius: "10px" }}
              alt="logo"
            />
          );
          const qrcode = (
            <QrCodeComponent id={row.orgId} row={row} theme={theme} />
          );
          return {
            ...row,
            logo,
            qrcode,
            action,
          };
        })
      : [];

  return (
    <div style={{ width: "100%" }}>
      <AddButton setSearch={setSearch} showButton={false} />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={!loading && rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Organization List"
        rowPerPage={50}
        onSelectedDelete={() => {}}
      />
      <AbstractModal
        show={showEditModal}
        Component={
          <EditOrganizationForm
            id={editId}
            handleClose={() => setShowEditModal(false)}
          />
        }
        handleClose={() => setShowEditModal(false)}
      />
    </div>
  );
};

export default OrganizationList;
