import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import type { RootState } from "../reducers/store";
import moment from "moment";
import { useMutation } from "@apollo/client";
import CreateWorkingDays from "../graphql/mutations/CreateWorkingDays";
import { Button } from "@mui/material";

interface BoxProps {
    heading: string;
}
const Box: React.FC<BoxProps> = ({ heading }) => {
    return (
        <div className="card-container m-2">
            <h2 className="text-center" style={{ color: '#ccc' }}>{heading}</h2>
        </div>
    )
}
const HomePage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.app)
    const [currentDateTime, setCurrentDateTime] = useState(moment());
    const [createWorkingDays] = useMutation(CreateWorkingDays)
    const [isCreated, setCreated] = useState(false)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(moment());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleCreate = () => {
        createWorkingDays({
            variables: {
                year: Number(moment().format('YYYY'))
            },
            onCompleted: (data) => {
                if(data.createWorkingDays.success){
                    localStorage.setItem('workingDays', JSON.stringify({year: moment().format('YYYY')}))
                    setCreated(true)
                }
            },
            onError: (err) => {
                console.log('Error', err);
            }
        })
    }

    useEffect(() => {
        const working = localStorage.getItem('workingDays')
        const isCreated = working && (JSON.parse(working).year === moment().format('YYYY'))
        setCreated(isCreated ? isCreated : false)
    }, [])

    return (
        <div className="mt-4 ms-3 d-flex flex-wrap align-items-center">
            <div className="w-100 d-flex justify-content-end">
                <div className="me-3 text-center" style={{ backgroundColor: '#4f677f', height: 100, width: 300, borderRadius: 10 }}>
                    <h2 style={{ color: '#eee' }}>{currentDateTime.format('MMMM Do YYYY')}</h2>
                    <div className="d-flex justify-content-center align-items-center">
                        <h2 style={{ color: '#eee', width: 130 }}>{currentDateTime.format('h:mm:ss')}</h2>
                        <h2 style={{ color: '#eee' }}>{currentDateTime.format('A')}</h2>
                    </div>
                </div>
                {user?.isSuperuser && !isCreated && <div>
                    <Button variant="contained" onClick={handleCreate}>Create Working Days</Button>
                </div>}
            </div>
            <Box heading="Announcements" />
            <Box heading="Organization" />
            <Box heading="Users" />
            <Box heading="Attendances" />
            <Box heading="Visits" />
            <Box heading="New Users" />
            <Box heading="Recent Visits" />
            <Box heading="Birthdays" />
        </div>
    )
}

export default HomePage