import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/store";
import { themes } from "../service";

interface ModalType {
  show: boolean;
  handleClose: () => void;
  Component: React.ReactNode;
  heading?: String;
}
const AbstractModal:React.FC<ModalType> = ({
  show,
  handleClose,
  Component
}) => {
  const { theme } = useSelector((state:RootState) => state.sidebar)

  return (
    <>
      <Modal show={show} onHide={handleClose} className={`modal-${theme}`}>
        <Modal.Header className="position-relative">
          <span
            className="position-absolute"
            style={{ top: 2, right: 5, cursor: "pointer", color: themes[theme].sidebar.color }}
            onClick={handleClose}>
            <CloseIcon />
          </span>
        </Modal.Header>
        <Modal.Body>
          {Component}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AbstractModal;
