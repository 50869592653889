import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {Moment} from 'moment';

import { Button } from "@mui/material";
import UpdateAttendance from "../../graphql/mutations/UpdateAttendance";
import type { RootState } from '../../reducers/store';

interface FormProps {
    theme: any;
    editId: number | undefined;
    handleClose: () => void
}
const UpdateAttendanceForm: React.FC<FormProps> = ({ theme, editId, handleClose }) => {
    const [value, setValue] = useState<Moment | null>()
    const [updateAttendance] = useMutation(UpdateAttendance)
    const { user } = useSelector((state: RootState) => state.app)
    console.log('Value', value?.format('HH:mm:ss'));
    const handleSubmit = () => {
        console.log('Value', value);

        if (!value)
            return
        updateAttendance({
            variables: {
                id: Number(editId),
                input: {
                    organization: user?.organization,
                    outTime: value.format('HH:mm:ss').toString()
                }
            },
            onCompleted: (data) => {
                console.log('Data', data);
            },
            onError: (err) => {
                console.log("Error", err);
            },
            refetchQueries: ['GetAttendance']
        })
        handleClose()
    }
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={['TimePicker', 'TimePicker']}>
                    <TimePicker
                        label="Select Out Time"
                        value={value}
                        className={`time-picker ${theme}`}
                        onChange={(newValue: any) => setValue(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <Button variant="contained" className="mt-3 ms-auto" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    )
}

export default UpdateAttendanceForm