import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material"

import List from "./List";
import GetAttendance from "../../graphql/queries/GetAttendance";
import AddButton from "../common/AddButton";
import { notification, themes } from "../../service/index";
import type { Direction } from "../../service/types";
import type { RootState } from "../../reducers/store";
import MonthFilter from "../common/MonthFilter";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import UpdateAttendanceForm from "../forms/UpdateAttendanceForm";

const AttendanceList: React.FC = () => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("name");
  const [dir, setDir] = useState<Direction>("desc");
  const [editId, setEditId] = useState<number | undefined>()
  const [show, setShow] = useState(false)
  const { userId } = useParams()
  const { monthFilter } = useSelector((state: RootState) => state.attendance)
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const navigate = useNavigate()

  const handleSortingChange = useCallback((columnDir: any, orderBy: any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
  }, []);

  const { data, loading, error } = useQuery(GetAttendance, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search,
        userId,
        month: monthFilter ? Number(monthFilter) : moment().month() + 1
      }),
      order: `${order} ${dir}`,
    },
  });
  console.log('data', data);

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);


  const columns = [
    {
      name: "date",
      label: "Date",
      width: 100,
      align: "center",
    },
    {
      name: "inTime",
      label: "In Time",
      width: 100,
      align: "center",
    },
    {
      name: "outTime",
      label: "Out Time",
      width: 100,
      align: "center",
    },
    {
      name: "organization",
      label: "Organization",
      align: "center",
      width: 250,
    },
    {
      name: "action",
      label: "Action",
    },
  ];

  const handleEdit = (id: number) => {
    console.log('Id', id);
    setEditId(id)
    setShow(true)
  }

  const rows = (data?.attendances || []).map((row: any) => {
    const action = (
      <TableActionCell
        hideEdit={false}
        editLabel="Edit User"
        onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.attendance.id }}
        onDestroy={() => { }}
      />
    );
    return {
      ...row.attendance,
      username: `${row.user.firstName} ${row.user.lastName}`,
      organization: row.organization.name,
      action
    };
  });

  return (
    <div className="w-100 position-relative">
      <AddButton setSearch={setSearch} showButton={false} />
      <MonthFilter theme={theme} selected={monthFilter || moment().month() + 1} />
      <span
        className="position-absolute d-flex align-items-center"
        role="button"
        onClick={() => navigate("/attendance")}
        style={{ color: themes[theme].sidebar.color, top: 15, left: 20 }}
      >
        <ArrowBack className="me-2" />Back
      </span>
      <List
        initialDir={dir}
        initialOrder={order}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Attendance List"
        onSelectedDelete={() => { }}
        rowPerPage={50}
      />
      <AbstractModal Component={<UpdateAttendanceForm handleClose={() => setShow(false)} editId={editId} theme={theme} />} show={show} handleClose={() => setShow(false)} />
    </div>
  );
};

export default AttendanceList;
