import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import SubmitButton from "../../common/SubmitButtom";
import { notification, themes } from "../../../service";
import CreateOrganizationMudation from "../../../graphql/mutations/CreateOrganization";
import AddressForm from "../../common/AddressForm";
import { RootState } from "../../../reducers/store";
import OrganizationCommonForm from "./OrganizationCommonForm";
import { doLogout } from "../../../reducers/app";

const schema = yup
  .object({
    name: yup.string().required("Please Enter Organization Name"),
    slug: yup.string().required("Please Enter slug"),
    orgEmail: yup.string().email("Email must be valid!").required("Please Enter Email"),
    orgPhone: yup.string().required("Please Enter Mobile Number"),
    logo: yup.string().required("Logo field is required"),
    street: yup.string().required("Please enter street name"),
    city: yup.string().required("Please enter city"),
    state: yup.string().required("Please enter your state"),
    postalCode: yup.string().required("Please enter your postal code"),
    country: yup.string().required("Please enter your country"),
    lat: yup.string().required("Please enter latitude"),
    long: yup.string().required("Please enter longitude")
  })
  .required();

interface Props {
  handleClose: () => void
}

const AddOrganization: React.FC<Props> = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [createOrganization] = useMutation(CreateOrganizationMudation);
  const [isNext, setNext] = useState<boolean>(false);
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const { user } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onFormSubmit = async (v: any) => {
    const { orgEmail, orgPhone, name, slug, logo, ...address } = v;
    console.log("logo", logo);
    createOrganization({
      variables: {
        input: { orgEmail, name, slug, orgPhone, logo },
        address
      },
      onCompleted: (data) => {
        if (data && data.createOrganization && data.createOrganization.success) {
          notification({ message: "Organization created successfylly!" });
          handleClose();
          if(!user?.organization){
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            dispatch(doLogout())
            navigate("/login");
          }
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetOrganizations"],
    })
  };

  const handleNextCLick = () => {
    if (getValues('name') && getValues('slug') && getValues('logo') && getValues('orgEmail') && getValues('orgPhone')) {
      setNext(true)
      clearErrors()
    }
  }
  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center", color: themes[theme].sidebar.color }}>
        Add A New Organization
      </Typography>
      <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
        {!isNext && <OrganizationCommonForm register={register} errors={errors} setValue={setValue} clearErrors={clearErrors} />}
        {isNext && <AddressForm register={register} errors={errors} setValue={setValue} />}
        <div className="d-flex justify-content-between align-items-center mt-3">

          {isNext ? <button
            className="signup-btn"
            onClick={() => setNext(false)}>
            Back
          </button> : <span></span>}
          {
            isNext ? <SubmitButton label="Add Organization" /> :
              <button
                className="signup-btn"
                onClick={handleNextCLick}>
                Next
              </button>
          }
        </div>
      </form>
    </div>
  );
};

export default AddOrganization;
