import { Store } from "react-notifications-component";

interface notificationType {
  title?: String;
  message: String;
  type?: any;
}

export const notification = ({
  title = "",
  message,
  type = "success",
}: notificationType) => {
  Store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

// hex to rgba converter
export const hexToRgba = (hex: any, alpha: any) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const themes: any = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    header: {
      backgroundColor: "#ffffff",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
    form: {
      textField: {
        color: "#607489",
        backgroundColor: "#eee",
      },
      label: {
        color: "#1346a3",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    header: {
      backgroundColor: "#0b2948",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
    form: {
      textField: {
        color: "#8ba1b7",
        backgroundColor: "#ccc",
      },
      label: {
        color: "#8ba1b7",
      },
    },
  },
};

export const downloadImage = async (imageUrl:string, imageName = 'qr-code.png') => {
  try {
    // Fetch the image data
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = imageName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

export const imageToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getHeadingTitle = (path: string) => {
  switch (path) {
    case "/users":
      return "User Management";
    case "/organization":
      return "Organization Management";
    case "/address":
      return "Address Management";
    case "/business-role":
      return "Business Role Management";
    case "/permission":
      return "Permission Management";
    case "/attendance":
      return "Attendance Management";
    case "/leaves":
      return "Leaves Management";
    case "/notification":
      return "Manage Notification";
    default:
      return "Dashboard";
  }
};

export const handleRequestPermission = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          reject("User denied the request for Geolocation.");
        } else {
          reject("Geolocation is not available.");
        }
      }
    );
  });
};

export const calculateDistance = (lat1:number, lon1:number, lat2:number, lon2:number) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance; // return distance in km
}

export const SystemAndSuperAdmin = ['system', 'super']
export const systemAdminRoute = ['/organization', '/address']