import { gql } from "@apollo/client";

export default gql `
query GetEmpAddress($where: JSONString, $limit: Int, $offset: Int, $order: String){
    empAddresses(where: $where, limit: $limit, offset: $offset, order: $order){
      id
      state
      street
      city
      country
      postalCode
      organization
      firstName
      lastName
    }
  }
`