import { gql } from "@apollo/client";

export default gql`
query GetAddress($id: Int!){
    address(id: $id){
      id
      state
      street
      postalCode
      lat
      long
      city
      country
    }
  }
`