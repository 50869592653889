import React from "react";
import type { UserFilterType } from "../../service/types";
import OrganizationFIlter from "../common/OrganizationFIlter";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";

interface Props {
    filter: UserFilterType;
    initFilterValue: UserFilterType,
    onChange: (id: any) => void
}

const UserFilter: React.FC<Props> = ({ filter, onChange, initFilterValue }) => {
    const { user } = useSelector((state:RootState) => state.app);
    const { theme } = useSelector((state:RootState) => state.sidebar);
    return (
        <div className={`position-absolute shadow filter-container ${theme}`}>
            {user?.isSuperuser && user.organization === 1 && <label className="d-block">Organization</label>}
            {user?.isSuperuser && user.organization === 1 && <OrganizationFIlter onOrganizationChange={onChange} value={filter.organization} />}
            <div className="form-group mt-2">
                <input
                    type="checkbox"
                    className="filter-checkbox"
                    id="isActive"
                    checked={filter.isActive === null ? false : filter.isActive}
                    onChange={(e) => onChange((state: any) => ({ ...state, isActive: e.target.checked }))}
                />
                <label htmlFor="isActive">Only Active</label>
            </div>
            <div className="form-group mt-2">
                <input
                    type="checkbox"
                    className="filter-checkbox"
                    id="isStaff"
                    checked={filter.isStaff === null ? false : filter.isStaff}
                    onChange={(e) => onChange((state: any) => ({ ...state, isStaff: e.target.checked }))}
                />
                <label htmlFor="isStaff">Only Staff</label>
            </div>
            <button className="clear-filter-btn" onClick={() => onChange(initFilterValue)}>Clear Filter</button>
        </div>
    )
}

export default UserFilter;