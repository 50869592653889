import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import List from "./List";
import GetUserAttendance from "../../graphql/queries/GetUserAttendance";
import AddButton from "../common/AddButton";
import { notification } from "../../service/index";
import type { Direction } from "../../service/types";
import { RootState } from "../../reducers/store";
import MonthFilter from "../common/MonthFilter";
import GetWorkingDays from "../../graphql/queries/GetWorkingDays";
import { setWorkingDays } from "../../reducers/attendance";

const getMonth = (a: number, b: number | null) => {
  return (!b || a - b === 0) ? 
    moment().format('MMMM') : a - b > 0 ? 
    moment().subtract(a - b, 'months').format('MMMM') : 
    moment().add(b - a, 'months').format('MMMM')
}
const UserAttendanceList: React.FC = () => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("name");
  const [dir, setDir] = useState<Direction>("desc");
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const { workingDays, monthFilter } = useSelector((state: RootState) => state.attendance)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSortingChange = useCallback((columnDir: any, orderBy: any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
  }, []);

  const { data, loading, error } = useQuery(GetUserAttendance, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search,
        month: monthFilter ? Number(monthFilter) : moment().month() + 1
      }),
      order: `${order} ${dir}`,
      limit: 100
    },
  });

  const { data: working, loading: workingLoading } = useQuery(GetWorkingDays, {
    fetchPolicy: "network-only",
    variables: {
      where: JSON.stringify({
        month: getMonth(moment().month() + 1, monthFilter),
        year: moment().format('YYYY'),
        filter: 'month'
      })
    }
  })

  useEffect(() => {
    if (!workingLoading && working && working.workingDays) {
      dispatch(setWorkingDays(working.workingDays.totalWorkingDays))
    }
  }, [workingLoading, working, dispatch])

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "username",
      label: "User Name",
      width: 100,
    },
    {
      name: "present",
      label: "Present",
      width: 100,
      align: "center",
    },
    {
      name: "absent",
      label: "Absent",
      width: 100,
      align: "center",
    },
    {
      name: "working",
      label: "Total Working Days",
      align: "center",
      width: 100,
    },
  ];

  const rows = (data?.userAttendance || []).map((row: any) => {
    return {
      ...row,
      username: `${row.firstName} ${row.lastName}`,
      working: workingDays
    };
  });

  const handleRowClick = (row: any) => {
    console.log('row', row);
    navigate(`/attendance/${row.userId}`)
  }
  return (
    <div className="w-100">
      <AddButton setSearch={setSearch} showButton={false} />
      <MonthFilter theme={theme} selected={monthFilter || moment().month() + 1} />
      <List
        initialDir={dir}
        initialOrder={order}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Attendance List"
        onSelectedDelete={() => { }}
        rowPerPage={50}
        handleRowClick={handleRowClick}
      />
    </div>
  );
};

export default UserAttendanceList;
