import React, { useState, useEffect } from 'react'

import TextField from '../../common/TextField'
import DragAndDrop from '../../common/DragAndDrop';
import { imageToBase64 } from '../../../service';

interface Props {
    register: any;
    errors: any;
    setValue: any;
    clearErrors: any
}
const OrganizationCommonForm: React.FC<Props> = ({ register, errors, setValue, clearErrors }) => {
    const [file, setFile] = useState(null)

    useEffect(() => {
        console.log('file', file);
        const setFileField = async (file:any) => {
          if(file){
            const base64:any = await imageToBase64(file);
            setValue("logo", base64)
            clearErrors('logo')
          }else{
            setValue('logo', '')
          }
        }
        setFileField(file)
      }, [setValue, file, clearErrors])

    return (
        <div>
            <TextField
                label="Organization Name"
                placeholder="Enter Organization Name"
                customId="name"
                type="text"
                register={register}
                errors={errors?.name?.message}
            />
            <TextField
                label="Organization Email"
                placeholder="Enter Organization Email"
                customId="orgEmail"
                type="email"
                register={register}
                errors={errors?.orgEmail?.message}
            />
            <TextField
                label="Organization Mobile Number"
                placeholder="Enter Organization Mobile Number"
                customId="orgPhone"
                type="text"
                register={register}
                errors={errors?.orgPhone?.message}
            />
            <TextField
                label="Slug"
                placeholder="Enter Slug"
                customId="slug"
                type="text"
                register={register}
                errors={errors?.slug?.message}
            />
            <label htmlFor="" style={{ color: "#1346a3", fontWeight: "bold" }}>
                Select Logo
            </label>
            <DragAndDrop setFile={setFile} file={file} />
            <p style={{ color: "red", marginTop: 5 }}>{errors.logo?.message}</p>
        </div>
    )
}

export default OrganizationCommonForm