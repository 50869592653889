import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import AbstractModal from "./AbstractModal";
import ChangePasswordForm from "./forms/users/ChangePassword";
import { doLogout } from "../reducers/app";
import { notification, getHeadingTitle, themes } from "../service";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/store";

const Header:React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useSelector((state:RootState) => state.sidebar)

  const handleShowClick = () => {
    setShowMenu(false);
    setShow(!show);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(doLogout());
    notification({ message: "Logout Successfully!" });
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => window.removeEventListener('click', handleOutsideClick)
  }, [])
  
  const handleOutsideClick = (e:any) => {
    if(!e.target.closest('.header-profile') && !e.target.closest('.profile-menu')){
      setShowMenu(false)
    }
  }
  console.log('location.pathname', location.pathname);
  
  return (
    <div
      style={{ width: "100%", background: themes[theme].header.backgroundColor }}
      className="d-flex justify-content-between align-items-center py-2 shadow-lg p-2"
    >
      <h2 style={{ color: "#70d370" }}>{getHeadingTitle(location.pathname.includes('/attendance') ? '/attendance': location.pathname)}</h2>
      <div>
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 50,
            cursor: "pointer",
            backgroundImage: 'url("/images/profile.png")',
            marginRight: "10px",
          }}
          className="header-profile position-relative"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu && (
            <div className="position-absolute profile-menu">
              <span
                role="button"
                className="menu-btn mb-1"
                onClick={handleShowClick}
              >
                Change Password
              </span>
              <span
                role="button"
                className="menu-btn"
                onClick={() => handleLogout()}
              >
                Logout
              </span>
            </div>
          )}
          <AbstractModal
            show={show}
            handleClose={handleShowClick}
            Component={<ChangePasswordForm handleClose={handleShowClick} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
