import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface sidebarType {
    monthFilter: number | null;
    workingDays: number | null;
}
const initialState:sidebarType = {
    monthFilter: null,
    workingDays: null
}

const attendance = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        setMonthFilter: (state, action:PayloadAction<number>) => {
            state.monthFilter = action.payload
        },
        setWorkingDays: (state, action:PayloadAction<number>) => {
            state.workingDays = action.payload
        }
    }
});

export const { setMonthFilter, setWorkingDays } = attendance.actions;

export default attendance.reducer;