import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import UpdateOrganization from "../../../graphql/mutations/UpdateOrganization";
import GetOrganizations from "../../../graphql/queries/GetOrganizations";
import DragAndDrop from "../../common/DragAndDrop";
import { imageToBase64, notification, themes } from "../../../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store";

const schema = yup
  .object({
    name: yup.string().required("Please Enter Organization Name"),
    orgEmail: yup.string().email("Please enter valid email").required("Please Enter Organization EMail"),
    orgPhone: yup.string().required("Please Enter Organization Mobile Number"),
    slug: yup.string().required("Please Enter Slug"),
  })
  .required();

interface Props {
  id: number | null,
  handleClose: () => void
}

const EditOrganizationForm: React.FC<Props> = ({ id, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const { theme } = useSelector((state: RootState) => state.sidebar);
  const [updateOrganization] = useMutation(UpdateOrganization);
  const [file, setFile] = useState(null);
  console.log('eidt org id', id);
  
  const { data, loading, error } = useQuery(GetOrganizations, {
    fetchPolicy: "network-only",
    variables: {
      id
    },
  });

  useEffect(() => {
    console.log('data', data);
    
    if (!loading && data && data.organizations) {
      const {name, slug, logo, orgEmail, orgPhone} = data.organizations.edges[0].node;
      console.log('logo', logo);
      setFile(logo)
      setValue("name", name);
      setValue("slug", slug);
      setValue("orgEmail", orgEmail);
      setValue("orgPhone", orgPhone);
    }
    if (error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, data, error, setValue]);

  const onFormSubmit = async (v: any) => {
    const logo = typeof file !== "string" ? await imageToBase64(file) : file
    updateOrganization({
      variables: {
        input: { ...v, logo },
        organization_id: id,
      },
      refetchQueries: ["GetOrganizations"],
    })
      .then(({ data }) => {
        if (data && data?.updateOrganization) {
          notification({ message: "Organization Updated successfully!" });
          handleClose();
        }
      })
      .catch((err) => {
        notification({ message: err.message, type: "danger" });
      });
  };
  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center", color: themes[theme].sidebar.color }}>
        Edit Organization
      </Typography>
      <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
        <TextField
          label="Organization Name"
          placeholder="Enter Organization Name"
          customId="name"
          type="text"
          register={register}
          errors={errors?.name?.message}
        />
        <TextField
          label="Organization Email"
          placeholder="Enter Organization Email"
          customId="orgEmail"
          type="email"
          register={register}
          errors={errors?.orgEmail?.message}
        />
        <TextField
          label="Organization Mobile Number"
          placeholder="Enter Organization Mobile Number"
          customId="orgPhone"
          type="text"
          register={register}
          errors={errors?.orgPhone?.message}
        />
        <TextField
          label="Slug"
          placeholder="Enter Slug"
          customId="slug"
          type="text"
          register={register}
          errors={errors?.slug?.message}
        />
        <label htmlFor="" style={{ color: "#1346a3", fontWeight: "bold" }}>
          Select Logo
        </label>
        <DragAndDrop file={file} setFile={setFile} />
        <SubmitButton label="Edit Organization" />
      </form>
    </div>
  );
};
export default EditOrganizationForm;
