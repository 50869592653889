import { useSelector } from 'react-redux';
import type { RootState } from '../reducers/store';

export const useAllowedRole = () => {
    const { user } = useSelector((state:RootState) => state.app);
    
    let allowedRole = 'guest'
    if(user?.isStaff){
        allowedRole = 'staff'
    }
    if(user?.isSuperuser){
        allowedRole = 'super'
    }
    if(user?.isSuperuser && user.organization && user.organization === 1){
        allowedRole = 'system'
    }
    if(!user?.organization){
        allowedRole = 'guest'
    }
    return allowedRole
}   
