import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";

import List from "./List";
import GetAddresses from "../../graphql/queries/GetAddresses";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import EditAddress from '../forms/Address/EditAddress';
import { notification } from "../../service";
import type { Direction } from "../../service/types";
import AddButton from "../common/AddButton";

const AddressList:React.FC = () => {
  const [order, setOrder] = useState<string>("street");
  const [search, setSearch] = useState<string>("");
  const [dir, setDir] = useState<Direction>("desc");
  const [editId, setEditId] = useState<number|null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const handleSortingChange = useCallback((columnDir:any, orderBy:any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
    // eslint-disable-next-line
  }, []);

  const { data, loading, error } = useQuery(GetAddresses, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search
      }),
      order: `${order} ${dir}`
    },
  });

  useEffect(() => {
    if (error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const handleEdit = (id:number) => {
    setEditId(id);
    setShowEditModal(true);
  };

  const handleDestroy = (id:number) => {
    
  };

  const columns = [
    {
      name: "street",
      label: "Street",
      width: 100,
    },
    {
      name: "city",
      label: "City",
      align: "center",
      width: 100,
    },
    {
      name: "state",
      label: "State",
      align: "center",
    },
    {
      name: "postalCode",
      label: "Postal Code",
      align: "center",
      width: 100
    },
    {
      name: "country",
      label: "Country",
      align: "center",
      width: 100,
    },
    {
      name: "lat",
      label: "Latitude",
      align: "center",
      width: 100,
    },
    {
      name: "long",
      label: "Longitude",
      align: "center",
      width: 100,
    },
    {
      name: "organization",
      label: "Organization Name",
      align: "center",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const rows = (data?.addresses || []).map((row:any) => {
    const action = (
      <TableActionCell
        hideEdit={false}
        editLabel="Edit User"
        onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.address.id }}
        onDestroy={handleDestroy}
      />
    );
    return {
      ...row.address,
      organization: row.organization.name,
      action,
    };
  });

  return (
    <div>
      <AddButton setSearch={setSearch} showButton={false} />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Address List"
        onSelectedDelete={() => {}}
        rowPerPage={50}
      />
      <AbstractModal
        show={showEditModal}
        Component={<EditAddress handleClose={() => setShowEditModal(false)} id={editId}/>}
        handleClose={() => setShowEditModal(false)}
      />
    </div>
  );
};

export default AddressList;
