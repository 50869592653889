import { gql } from "@apollo/client";

export default gql`
query GetOrgAdresses($where: JSONString, $limit: Int, $offset: Int, $order: String){
    addresses(where: $where, limit: $limit, offset: $offset, order: $order){
      organization{
        name
        orgId
      }
      address{
        id
        street
        city
        state
        postalCode
        country
        lat
        long
      }
    }
  }
`