import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";

import SubmitButton from "../../common/SubmitButtom";
import UpdateAddress from "../../../graphql/mutations/UpdateAddress";
import GetAddress from "../../../graphql/queries/GetAddress";
import AddressForm from "../../common/AddressForm";
import { notification, themes } from "../../../service";
import { useSelector } from "react-redux";
import type { RootState } from "../../../reducers/store";

const schema = yup
  .object({
    street: yup.string().required("Please enter street name"),
    city: yup.string().required("Please enter city"),
    state: yup.string().required("Please enter your state"),
    postalCode: yup.string().required("Please enter your postal_code"),
    country: yup.string().required("Please enter your country"),
    lat: yup.string().required("Please enter latitude"),
    long: yup.string().required("Please enter longitude")
  })
  .required();

  interface Props{
    id: number|null;
    handleClose: () => void;
  }

const EditAddress:React.FC<Props> = ({ id, handleClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [updateAddress] = useMutation(UpdateAddress);
  const {theme} = useSelector((state:RootState) => state.sidebar)
  const { data, loading, error } = useQuery(GetAddress, {
    fetchPolicy: "network-only",
    variables: {
      id: Number(id)
    },
  });

  useEffect(() => {
    console.log('data', data);
    if (!loading && data && data.address) {
      const {
        address: { street, city, state,country,postalCode,lat,long },
      } = data;
      setValue("street", street);
      setValue("city", city);
      setValue("state", state);
      setValue("country", country);
      setValue("postalCode", postalCode);
      setValue("lat", lat);
      setValue("long", long);
    }
    if (error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, data, error, setValue]);

  const onFormSubmit = (v:any) => {
    updateAddress({
      variables: {
        input: { ...v },
        id,
      },
      refetchQueries: ["GetAddresses"],
    })
      .then(({ data }) => {
        if (data && data?.updateAddress && data?.updateAddress?.id) {
          notification({ message: "Address Updated successfully!" });
          handleClose();
        }
      })
      .catch((err) => {
        notification({ message: err.message, type: "danger" });
        handleClose();
      });
  };
  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center", color: themes[theme].sidebar.color }}>
        Edit Address
      </Typography>
      <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
        <AddressForm register={register} setValue={setValue} errors={errors} isUser />
        <SubmitButton label="Edit address" />
      </form>
    </div>
  );
};
export default EditAddress;
