import { gql } from "@apollo/client";

export default gql`
    query GetUsers($where: JSONString){
        users(where: $where) {
            userId
            firstName
            lastName
        }
    }
`