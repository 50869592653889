import React from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import GetUsers from "../../../graphql/queries/GetUsersForRole";
import GetBusinessRoles from "../../../graphql/queries/GetBusinessRoles";
import SubmitButton from "../../common/SubmitButtom";
import CreateUserRoleMutation from '../../../graphql/mutations/CreateUserRole';
import { notification, themes } from "../../../service/index";
import SelectField from "../../common/SelectField";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store";


const schema = yup
    .object({
        role: yup.string().required("Please Select Business Role"),
        user: yup.string().required("Please Select User"),
    })

interface Props {
    theme: any;
    handleClose: () => void;
}

const CreateUserRole: React.FC<Props> = ({ handleClose, theme }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            role: "",
            user: ""
        }
    });

    const { user } = useSelector((state: RootState) => state.app);

    const { loading, data } = useQuery(GetUsers, {
        fetchPolicy: 'network-only',
        variables: {
            where: JSON.stringify({
                organization: user?.organization,
                // isStaff: false,
            })
        }
    })

    const { data: BusinessData, loading: BusinessLoading } = useQuery(GetBusinessRoles, {
        fetchPolicy: "network-only",
        variables: {
            where: JSON.stringify({
                organization: user?.organization
            })
        }
    })

    const [createRole] = useMutation(CreateUserRoleMutation);
    const onFormSubmit = (v: any) => {
        createRole({
            variables: {
                input: {
                    role: Number(v.role),
                    user: Number(v.user),
                },
            },
            onCompleted: (data) => {
                if (data.createRole.success) {
                    notification({ message: "Business role assigned successfully!" })
                    handleClose()
                }
            },
            onError: (err) => {
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetUserRoles']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>Assign User Role</Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SelectField data={BusinessLoading ? [] : BusinessData?.userTypes?.map((item: any) => ({ label: item.role, value: Number(item.id) }))} errors={errors} label="Select Business Role" name="role" register={register} />
                <SelectField data={loading ? [] : data?.users?.map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: Number(user.userId) }))} errors={errors} label="Select User" name="user" register={register} />
                <SubmitButton label="Create" />
            </form>
        </div>
    )
}
export default CreateUserRole;