import React from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "../components/common/TextField";
import SubmitButton from "../components/common/SubmitButtom";
import { notification } from "../service";

interface VisitType {
    firstName: string
    mobile: string
    lastName: string
    purpose: string
}

const schema = yup
    .object({
        firstName: yup.string().required("Please Enter First Name"),
        lastName: yup.string().required("Please Enter Last Name"),
        mobile: yup.string().required("Please Enter Last Name"),
        purpose: yup.string().required("This Field is Required"),
    })
    .required()

const Client:React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onFormSubmit = (v: VisitType) => {
        console.log('Value', v);
    }
  return (
    <div className="login-signup-bg pt-md-5 pt-4">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={8} md={6} lg={4}>
                    <div className="login-form-wrapper">
                        <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: '#1346a3' }}>
                            Welcome
                        </Typography>
                        <form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                            <TextField
                                label="First Name"
                                placeholder="Please Enter First Name"
                                customId="firstName"
                                register={register}
                                errors={errors?.firstName?.message}
                            />
                            <TextField
                                label="Last Name"
                                placeholder="Please Enter Last Name"
                                customId="lastName"
                                register={register}
                                errors={errors?.lastName?.message}
                            />
                            <TextField
                                label="Mobile"
                                placeholder="Please Enter Mobile Number"
                                customId="mobile"
                                register={register}
                                errors={errors?.mobile?.message}
                            />
                            <TextField
                                label="Purpose of Visit"
                                placeholder="Please Enter Purpose of Visit"
                                customId="purpose"
                                register={register}
                                errors={errors?.purpose?.message}
                            />
                            <SubmitButton label="Submit" />
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
  )
}

export default Client