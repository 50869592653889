import React, { useState, useCallback, useEffect } from "react";
import { useQuery,useMutation } from "@apollo/client";

import List from "./List";
import GetBusinessRoles from "../../graphql/queries/GetBusinessRoles";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import EditBusinessRole from "../forms/BusinessRoles/EditBusinessRole";
import AddButton from "../common/AddButton";
import CreateUserType from "../forms/BusinessRoles/CreateUserType";
import { notification } from "../../service/index";
import DeleteBusinessRole from "../../graphql/mutations/DeleteBusinessRole";
import type { Direction } from "../../service/types";
import { confirmAlert } from "react-confirm-alert";
import CustomAlert from "../common/CustomAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";

const BusinessRoleList:React.FC = () => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("name");
  const [dir, setDir] = useState<Direction>("desc");
  const [editId, setEditId] = useState<number|null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [deleteBusinessRole]=useMutation(DeleteBusinessRole);
  const { theme } = useSelector((state: RootState) => state.sidebar)


  const handleSortingChange = useCallback((columnDir:any, orderBy:any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
  }, []);

  const { data, loading, error, refetch } = useQuery(GetBusinessRoles, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search,
      }),
      order: `${order} ${dir}`,
    },
  });
  console.log('data', data);
  
  useEffect(() => {
    if (error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [error]);

  const handleEdit = (id:number) => {
    setEditId(id);
    setShowEditModal(true);
  };

  const onDelete = (id: number) => {
    deleteBusinessRole({
      variables: {
        id: Number(id)
      },
      onCompleted: (data) => {
        if (data && data.deleteUserType && data.deleteUserType.success) {
          notification({ message: `Business Role deleted successfully!`});
          refetch()
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetBusinessRoles"]
    })
  }

  const handleDestroy = (id: number) => {
    confirmAlert({
      customUI: ({onClose}) => (
      <CustomAlert 
        onClose={onClose} 
        id={id} 
        callback={onDelete} 
        message="Are yor sure, You want to delete this business role"
        theme={theme} 
      />)
    })
  };

  const columns = [
    {
      name: "role",
      label: "User Type",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const rows =
     (data?.userTypes || []).map((row:any) => {
          const action = (
            <TableActionCell
              hideEdit={false}
              editLabel="Edit User"
              onEdit={handleEdit}
              hideDestroy={false}
              tableRow={{ rowId: row.id }}
              onDestroy={handleDestroy}
            />
          );

          return {
            ...row,
            action
          };
        });

  return (
    <div>
      <AddButton show={show} setShow={setShow} setSearch={setSearch} />
      <List
        initialDir={dir}
        initialOrder={order}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="UserType List"
        onSelectedDelete={() => {}}
        rowPerPage={50}
      />
      <AbstractModal
        show={show}
        Component={
          <CreateUserType
            theme={theme}
            handleClose={() => setShow(false)}
            refetch={refetch}
          />
        }
        handleClose={() => setShow(false)}
      />
      <AbstractModal
        show={showEditModal}
        Component={
          <EditBusinessRole
            id={editId}
            handleClose={() => setShowEditModal(false)}
            refetch={refetch}
            theme={theme}
          />
        }
        handleClose={() => setShowEditModal(false)}
      />
    </div>
  );
};

export default BusinessRoleList;
