import React from 'react'
import { Select, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux';
import { setMonthFilter } from '../../reducers/attendance';

interface Props{
    theme: any,
    selected: number,
}
const MonthFilter:React.FC<Props> = ({theme, selected}) => {
    const dispatch = useDispatch()
    const handleChange = (e:any) => {
        dispatch(setMonthFilter(e.target.value))
    }
  return (
    <div className="d-flex justify-content-end" style={{marginRight: 32}}>
          <Select className={`month-filter ${theme}`}  style={{width: 250}} value={selected} onChange={handleChange}>
            <MenuItem selected disabled value="">Select Month</MenuItem>
            <MenuItem value="1">January</MenuItem>
            <MenuItem value="2">February</MenuItem>
            <MenuItem value="3">March</MenuItem>
            <MenuItem value="4">April</MenuItem>
            <MenuItem value="5">May</MenuItem>
            <MenuItem value="6">June</MenuItem>
            <MenuItem value="7">July</MenuItem>
            <MenuItem value="8">August</MenuItem>
            <MenuItem value="9">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
        </div>
  )
}

export default MonthFilter