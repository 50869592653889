import React from 'react'
import { useParams } from 'react-router-dom'

import GetUserDetails from '../graphql/queries/GetUserDetails';
import { useQuery } from '@apollo/client';

const UserDetails:React.FC = () => {
    const { id } = useParams()
    console.log('location', id);
    const { loading, data, error } = useQuery(GetUserDetails, {
      fetchPolicy: 'network-only',
      variables: {
        id: Number(id)
      }
    })
    console.log('data', data);
    if(error){
      return <p>{error.message}</p>
    }
    if(loading){
      return <p>Loading....</p>
    }
    
  return (
    <div>UserDetails</div>
  )
}

export default UserDetails