import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface sidebarType {
    organization: boolean
}
const initialState:sidebarType = {
    organization: false
}

const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setOrgModal: (state, action:PayloadAction<boolean>) => {
            state.organization = action.payload
        },
    }
});

export const { setOrgModal } = modal.actions;

export default modal.reducer;