import React from "react";

interface Props{
    label: String,
    disabled?: boolean
}
const SubmitButton:React.FC<Props> = ({ label, disabled }) => {
    return (
        <div style={{marginTop: 10, textAlign: 'right'}}>
            <button type="submit" disabled={!!disabled} className={`signup-btn ${disabled ? 'disabled':''}`}>{label}</button>
        </div>
    )
}
export default SubmitButton;