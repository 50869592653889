import React, { useState, useCallback, useEffect } from "react";
import { useQuery,useMutation } from "@apollo/client";

import List from "./List";
import GetUserRoles from "../../graphql/queries/GetUserRoles";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import AddButton from "../common/AddButton";
import CreateUserRole from "../forms/BusinessRoles/CreateUserRole";
import EditUserRole from "../forms/BusinessRoles/EditUserRole";
import { notification } from "../../service/index";
import DeleteUserRole from "../../graphql/mutations/DeleteUserRole";
import type { Direction } from "../../service/types";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { confirmAlert } from "react-confirm-alert";
import CustomAlert from "../common/CustomAlert";

const PermissionList:React.FC = () => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("name");
  const [dir, setDir] = useState<Direction>("desc");
  const [editId, setEditId] = useState<number | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const {theme} = useSelector((state: RootState) => state.sidebar)

  const [deleteRole]=useMutation(DeleteUserRole);

  const handleSortingChange = useCallback((columnDir:any, orderBy:any) => {
    const isDesc = columnDir === "asc";
    setDir(isDesc ? "desc" : "asc");
    setOrder(orderBy);
  }, []);

  const { data, loading, error } = useQuery(GetUserRoles, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search,
      }),
      order: `${order} ${dir}`,
    },
  });
  console.log('data', data);
  
  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const handleEdit = (id: number) => {
    setEditId(id);
    setShowEditModal(true);
  };

  const onDelete = (id: number) => {
    deleteRole({
      variables: {
        id
      },
      onCompleted: (data) => {
        if(data && data.deleteRole && data.deleteRole.success){
          notification({ message: `User Role deleted successfully!`});
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetUserRoles"]
    })
  }
  const handleDestroy = (id:number) => {
    confirmAlert({
      customUI: ({ onClose }) =>(
        <CustomAlert 
          callback={onDelete} 
          onClose={onClose} 
          id={id} 
          message="Are Your sure, You want to delete user role" 
          theme={theme} 
        />
      )
    })
    
  };

  let columns = [
    {
      name: "username",
      label: "User Name",
      width: 100,
    },
    {
      name: "userRole",
      label: "User Type",
      align: 'center',
      width: 150
    },
    {
      name: "action",
      label: "Actions",
      align: "left",
    },
  ];

  const rows = (data?.roles || []).map((row:any) => {
          const action = (
            <TableActionCell
              hideEdit={false}
              editLabel="Edit User"
              onEdit={handleEdit}
              hideDestroy={false}
              tableRow={{ rowId: row.id }}
              onDestroy={handleDestroy}
            />
          );

          return {
            ...row,
            username: `${row.firstName} ${row.lastName}`,
            action,
          };
        });

  return (
    <div className="w-100">
      <AddButton show={show} setShow={setShow} setSearch={setSearch} />
      <List
        initialDir={dir}
        initialOrder={order}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="UserType List"
        onSelectedDelete={() => {}}
        rowPerPage={50}
      />
      <AbstractModal
        show={show}
        Component={
          <CreateUserRole
            handleClose={() => setShow(false)}
            theme={theme}
          />
        }
        handleClose={() => setShow(false)}
      />
      <AbstractModal
        show={showEditModal}
        Component={
          <EditUserRole
            handleClose={() => setShowEditModal(false)}
            theme={theme}
            id={editId}
          />
        }
        handleClose={() => setShowEditModal(false)}
      />
    </div>
  );
};

export default PermissionList;
