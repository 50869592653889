import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type themeType = 'dark'|'light'

interface sidebarType {
    collapsed: boolean
    theme: themeType
}
const initialState:sidebarType = {
    collapsed: false,
    theme: 'light'
}

const sidebar = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setCollapse: (state, action:PayloadAction<boolean>) => {
            state.collapsed = action.payload
        },
        setTheme: (state, action:PayloadAction<themeType>) => {
            state.theme = action.payload
        }
    }
});

export const { setCollapse, setTheme } = sidebar.actions;

export default sidebar.reducer;