import { gql } from "@apollo/client"

export default gql`
query GetUserDetails($id: Int!){
  userDetails(id: $id){
    user{
      username
      firstName
      lastName
      email
      mobile
      isSuperuser
      isStaff
  }
    organization{
      name
      orgEmail
      orgPhone
      id
      logo
      slug
      qrCode
    }
    address {
      state
      street
      postalCode
      id
      city
      country
      lat
      long
    }
  }
}
`