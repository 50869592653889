import React from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import CreateBusinessRole from '../../../graphql/mutations/CreateBusinessRole';
import { notification, themes } from "../../../service/index";


const schema = yup
    .object({
        role: yup.string().required("Please Enter User Type"),
    })

    interface Props{
        theme: any;
        handleClose: () => void;
        refetch: () => void;
    }

const CreateUserType:React.FC<Props> = ({ handleClose, refetch, theme }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(schema),defaultValues:{
        role:""
    }});
    
    const [createUserType] = useMutation(CreateBusinessRole);
    const onFormSubmit = (v:any) => {
        createUserType({
            variables: {
                input: v,
            },
            onCompleted: (data) => {
                if(data.createUserType.success){
                    notification({message: "Business role created successfully!" })
                    refetch()
                    handleClose()
                }
            },
            onError: (err) => {
                notification({message: err.message, type: 'danger'})
            },
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>Create a New Business Role</Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="User Type"
                    placeholder="Enter User Type"
                    customId="role"
                    type="text"
                    register={register}
                    errors={errors?.role?.message}
                />
                <SubmitButton label="Create Role" />
            </form>
        </div>
    )
}
export default CreateUserType;