import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import GetUsers from "../../../graphql/queries/GetUsersForRole";
import GetBusinessRoles from "../../../graphql/queries/GetBusinessRoles";
import GetUserRoles from "../../../graphql/queries/GetUserRoles";
import SubmitButton from "../../common/SubmitButtom";
import UpdateUserRoleMutation from '../../../graphql/mutations/UpdateUserRole';
import { notification, themes } from "../../../service/index";
import SelectField from "../../common/SelectField";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store";


const schema = yup
    .object({
        role: yup.string().required("Please Select Business Role"),
        user: yup.string().required("Please Select User"),
    })

interface Props {
    theme: any;
    id: number|null;
    handleClose: () => void;
}

const EditUserRole: React.FC<Props> = ({ handleClose, theme, id }) => {
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            role: "",
            user: ""
        }
    });
    const [role, setRole] = useState<any>()
    const { user } = useSelector((state: RootState) => state.app);

    const { data: roleData, loading: roleLoading, error } = useQuery(GetUserRoles, {
        fetchPolicy: "cache-and-network",
        variables: {
          where: JSON.stringify({
            id: Number(id)
          })
        },
      });

      useEffect(() => {
        if(error){
            console.log("Error", error);
        }
        if(!roleLoading && roleData && roleData.roles){
            const [roles] = roleData.roles;
            setRole(roles)
        }
      }, [roleData, roleLoading, error])
      console.log('roledata', role);
      
    const { loading, data } = useQuery(GetUsers, {
        fetchPolicy: 'network-only',
        variables: {
            where: JSON.stringify({
                organization: user?.organization,
                isStaff: false,
            })
        }
    })

    const { data: BusinessData, loading: BusinessLoading } = useQuery(GetBusinessRoles, {
        fetchPolicy: "network-only",
        variables: {
            where: JSON.stringify({
                organization: user?.organization
            })
        }
    })

    const [updateRole] = useMutation(UpdateUserRoleMutation);
    const onFormSubmit = (v: any) => {
        console.log('value', v);
        
        updateRole({
            variables: {
                input: {
                    role: Number(v.role),
                    user: Number(v.user),
                },
                id: Number(id)
            },
            onCompleted: (data) => {
                if (data.updateRole.success) {
                    notification({ message: "Business role updated successfully!" })
                    handleClose()
                }
            },
            onError: (err) => {
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetUserRoles']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>Assign User Role</Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SelectField 
                    data={BusinessLoading ? [] : BusinessData?.userTypes?.map((item: any) => ({ label: item.role, value: Number(item.id) }))} 
                    errors={errors} 
                    label="Select Business Role" 
                    name="role" 
                    register={register}
                    value={role?.roleId}
                    setValue={setValue}
                />
                <SelectField 
                    data={loading ? [] : data?.users?.map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: Number(user.userId) }))} 
                    errors={errors} 
                    label="Select User" 
                    name="user" 
                    register={register} 
                    value={role?.userId}
                    setValue={setValue}
                />
                <SubmitButton label="Create" />
            </form>
        </div>
    )
}
export default EditUserRole;