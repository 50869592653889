import { gql } from "@apollo/client";

export default gql`
    mutation UpdateUser($input: UserUpdateInputType!, $userId: ID!){
        updateUser(input: $input, userId: $userId){
            user{
                firstName
            }
        }
    }
`