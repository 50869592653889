import { gql } from "@apollo/client";

export default gql`
    query GetUsers($where: JSONString, $limit: Int, $offset: Int, $order: String){
        users(where: $where, limit: $limit, offset: $offset, order: $order) {
            userId
            firstName
            lastName
            email
            organization
            organizationName
            isSuperuser
            isActive
            isStaff
            dob
            joiningDate
            mobile
        }
    }
`