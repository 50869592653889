import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import GetOrganizationsQuery from '../../graphql/queries/GetOrganizations'

interface Props {
    value?: number | null
    onOrganizationChange: (id: any) => void
}
const OrganizationFIlter: React.FC<Props> = ({ onOrganizationChange, value }) => {
    const [orgData, setOrgData] = useState([]);

    const { loading, data } = useQuery(GetOrganizationsQuery, {
        variables: {

        }
    })

    useEffect(() => {
        if (!loading && data && data.organizations) {
            const tempArr = data.organizations?.edges.map((item: any) => {
                return {
                    value: item.node.orgId,
                    label: item.node.name
                }
            })
            setOrgData(tempArr)
        }
    }, [loading, data])

    return (
        <select 
            className='mt-2 select-component' 
            value={value ? value : undefined} 
            style={{ width: 220 }} 
            onChange={(e: any) => onOrganizationChange((state: any) => ({ ...state, organization: e.target.value }))}
        >
            <option selected disabled>Select Organization</option>
            {
                (orgData || []).map((item: any, key: number) => (
                    <option key={key} value={item.value}>
                        {item.label}
                    </option>
                ))
            }
        </select>
    )
}

export default OrganizationFIlter