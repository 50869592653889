import React, { useEffect } from "react";
import TextField from "./TextField";
import { handleRequestPermission, notification } from "../../service";

interface Props {
  register: any;
  errors: any;
  setValue: any;
  isUser?: boolean
}
const AddressForm: React.FC<Props> = ({
  register,
  errors,
  setValue,
  isUser
}) => {

  useEffect(() => {
    const getPosition = async () => {
      const position: any = await handleRequestPermission()
      if (position.coords) {
        const { latitude, longitude } = position.coords
        if (latitude && longitude) {
          setValue('lat', latitude)
          setValue('long', longitude)
        }
      } else {
        notification({ title: 'Permission denied', message: "Permission denied!", type: 'warning' })
      }
    }
    if (!isUser)
      getPosition()
  }, [isUser, setValue])
  return (
    <div>
      <TextField
        label="Street address"
        placeholder="Enter your street address"
        customId="street"
        type="text"
        register={register}
        errors={errors?.street?.message}
      />
      <TextField
        label="City"
        placeholder="Enter Your city Name"
        customId="city"
        type="text"
        register={register}
        errors={errors?.city?.message}
      />
      <TextField
        label="State"
        placeholder="Enter Your State Name"
        customId="state"
        type="text"
        register={register}
        errors={errors?.state?.message}
      />
      <TextField
        label="Zip code"
        placeholder="Enter Postal code"
        customId="postalCode"
        type="text"
        register={register}
        errors={errors?.postalCode?.message}
      />
      <TextField
        label="Country"
        placeholder="Enter Your Country Name"
        customId="country"
        type="text"
        register={register}
        errors={errors?.country?.message}
      />
      {!isUser && <><TextField
        label="lat"
        placeholder="Enter Latitude Name"
        customId="lat"
        type="text"
        register={register}
        errors={errors?.lat?.message}
      />
        <TextField
          label="long"
          placeholder="Enter Longitude Name"
          customId="long"
          type="text"
          register={register}
          errors={errors?.long?.message}
        /></>}
    </div>
  );
};

export default AddressForm;
