import { gql } from "@apollo/client";

export default gql`
query GetBusinnessRoles($where: JSONString, $limit: Int, $offset: Int, $order: String){
  userTypes(where: $where, limit: $limit, offset: $offset, order: $order){
		id
    role
    organization {
      name
    }
  }
}
`