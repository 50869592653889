import { gql } from "@apollo/client";
export default gql`
query GetAttendance($where: JSONString, $limit: Int, $offset: Int, $order: String){
    attendances(where: $where, limit: $limit, offset: $offset, order: $order){
      attendance{
        id
        date
        inTime
        outTime
      }
      organization{
        name
        orgId
      }
      user{
        firstName
        lastName
        userId
      }
    }
  }
`