import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"
import { useNavigate } from "react-router-dom";

import List from "./List";
import GetUsers from "../../graphql/queries/GetUsers";
import ActivateDeactivateUser from "../../graphql/mutations/ActivateDeactivateUser";
import TableActionCell from "./TableActionCell";
import AbstractModal from "../AbstractModal";
import SignUp from "../forms/users/CreateUser";
import EditUserForm from "../forms/users/EditUser";
import { notification } from "../../service";
import AddButton from "../common/AddButton";
import type { RootState } from "../../reducers/store";
import UserFilter from "../filters/UserFilter";
import type { Direction, UserFilterType } from "../../service/types";
import CustomAlert from "../common/CustomAlert";

const initFilterValue: UserFilterType = {
  organization: null,
  isActive: true,
  isStaff: null
}

interface Props {
  organization?: number | null
}

const UsersList: React.FC<Props> = ({ organization }) => {
  const [order, setOrder] = useState<String>("firstName");
  const [search, setSearch] = useState<String>('');
  const [dir, setDir] = useState<Direction>('asc');
  const [editId, setEditId] = useState<number | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.app)
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const [activateDeactivateUser] = useMutation(ActivateDeactivateUser);
  const navigate = useNavigate()
  const [filter, setFilter] = useState<UserFilterType>({...initFilterValue, organization})

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);


  const { error, data, loading } = useQuery(GetUsers, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: JSON.stringify({
        search,
        ...filter,
      }),
      order: "username desc"
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const handleEdit = (id: number) => {
    setEditId(id);
    setShowEditModal(true);
  };

  const onDelete = (id: number) => {
    activateDeactivateUser({
      variables: {
        id
      },
      onCompleted: (data) => {
        if (data && data?.activateDeactivateUser && data?.activateDeactivateUser?.success) {
          notification({ message: "User deleted successfully!" });
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetUsers"]
    })
  }
  const handleDestroy = (id: number) => {
    confirmAlert({
      customUI: ({onClose}) =>
        <CustomAlert
          id={id}
          onClose={onClose}
          message="Are you sure you want to deactivate this user"
          callback={onDelete}
          theme={theme}
        />
    })
  };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      width: 100,
    },
    {
      name: "lastName",
      label: "Last Name",
      align: "center",
      width: 100,
    },
    {
      name: "email",
      label: "Email",
      align: "center",
      width: 100,
    },
    {
      name: "organizationName",
      label: "Organization",
      align: "center",
    },
    {
      name: "joiningDate",
      label: "Joining Date",
      align: "center",
    },
    {
      name: "dob",
      label: "Date of Birth",
      align: "center",
    },
    {
      name: "mobile",
      label: "Mobile Number",
      align: "center",
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const rows =
    (data?.users || []).map((row: any) => {
      const action = (
        <TableActionCell
          hideEdit={!(user?.isSuperuser || user?.isStaff)}
          editLabel="Edit User"
          onEdit={handleEdit}
          hideDestroy={!user?.isSuperuser}
          tableRow={{ rowId: row.userId }}
          onDestroy={handleDestroy}
        />
      );

      const role = row?.UserType?.name || row?.MainRole?.role;
      return {
        ...row,
        action,
        role,
      };
    });

    const handleRowClick = (row:any) => {
      navigate(`/users/${row.userId}`)
    }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton show={show} setShow={setShow} setSearch={setSearch} />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Users List"
        handleRowClick={handleRowClick}
        rowPerPage={50}
        filterComponent={<UserFilter filter={filter} onChange={setFilter} initFilterValue={initFilterValue} />}
        onSelectedDelete={() => { }}
      />
      <AbstractModal
        show={show}
        Component={<SignUp handleClose={() => setShow(false)} />}
        handleClose={() => setShow(false)}
      />
      <AbstractModal
        show={showEditModal}
        Component={<EditUserForm handleClose={() => setShowEditModal(false)} id={editId} />}
        handleClose={() => setShowEditModal(false)}
      />
    </div>
  );
};

export default UsersList;
