import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

import { client } from './graphql/ApolloClient';
import Routers from './Router';
import './App.css';
import { doLogin } from './reducers/app';
import type { RootState } from './reducers/store';
import { setTheme } from './reducers/sidebar';

function App() {
  const [isLoggedIn, setLogin] = useState(false);
  const [isComponentReady, setComponentReady] = useState(false);
  const dispatch = useDispatch();

  const { token } = useSelector((state:RootState) => state.app);

  useEffect(() => {
    if (!token) {
      const _token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      if (_token && user) {
        dispatch(doLogin({ token: _token, user: JSON.parse(user) }));
        setLogin(true);
      }
      setComponentReady(true);
    } else {
      setLogin(true);
      setComponentReady(true);
    }
  }, [token, dispatch]);

  useEffect(() => {
    const theme:any = localStorage.getItem('theme') || 'light'
    dispatch(setTheme(theme))
  }, [dispatch])

  return (
    <>
      {isComponentReady ? <ApolloProvider client={client}>
        <ReactNotifications />
        <Routers auth={isLoggedIn} />
      </ApolloProvider>
        : <h2>Loading...</h2>}
    </>
  );
}

export default App;
