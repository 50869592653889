import React, { memo, useCallback } from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';
import { themes } from '../../service';

interface moreActionsType {
  label: String;
  icon: React.ReactNode;
  action: () => void
}

interface tableRowType {
  rowId: number
}
interface propTypes {
  hideEdit?: boolean
  editLabel?: string
  onEdit: (id: number) => void;
  hideDestroy?: boolean;
  destroyLabel?: string;
  destroyIcon?: React.ReactNode
  onDestroy: (id: number) => void
  tableRow: tableRowType
  moreActions?: Array<moreActionsType>
};

const TableActionsCell: React.FC<propTypes> = ({
  hideEdit,
  editLabel,
  onEdit,
  hideDestroy,
  destroyLabel,
  destroyIcon,
  onDestroy,
  moreActions,
  tableRow
}) => {

  const { theme } = useSelector((state: RootState) => state.sidebar)
  // Method to handle edit
  const handleEdit = useCallback(
    (e: any) => {
      e.stopPropagation();
      onEdit(tableRow.rowId);
    },
    [tableRow, onEdit]
  );

  // Method to handle destroy
  const handleDestroy = useCallback(
    (e: any) => {
      e.stopPropagation();
      onDestroy(tableRow.rowId);
    },
    [tableRow, onDestroy]
  );

  // Method to handle destroy
  const handleMoreAction = useCallback(
    (e: any, action: any) => {
      e.stopPropagation();
      if (action) action(tableRow.rowId);
    },
    [tableRow]
  );

  return (
    <div className='d-flex'>
      {!hideEdit && (
        <Tooltip title={editLabel}>
            <IconButton style={{color: themes[theme].sidebar.color}}  onClick={handleEdit} aria-label={editLabel}>
              <EditIcon />
            </IconButton>
        </Tooltip>
      )}
      {moreActions?.map((actionItem, key) => (
        <Tooltip title={actionItem.label} key={key}>
          {
            <Button style={{color: themes[theme].sidebar.color}} onClick={e => handleMoreAction(e, actionItem.action)}>
              {!actionItem.icon ? actionItem.label : actionItem.icon}
            </Button>}
        </Tooltip>
      ))}
      {!hideDestroy && (
        <Tooltip title={destroyLabel}>
            <IconButton style={{color: themes[theme].sidebar.color}} onClick={handleDestroy} aria-label={destroyLabel}>
              {destroyIcon && destroyIcon}
              {!destroyIcon && <DeleteIcon />}
            </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default memo(TableActionsCell);
