import React, { memo, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TableSortLabel,
  Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip
} from '@mui/material';
import { Delete as DeleteIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';
import { themes } from '../../service';

type Direction = 'asc' | 'desc'
type themeType = 'dark' | 'light'

type EnhanceTableType = {
  numSelected: number;
  rowCount: number;
  columns: any;
  orderBy: String;
  order: Direction;
  theme: themeType;
  onSortingChange: (x: String, y: String) => void
  onSelectAllClick: (e: any) => void
}

function EnhancedTableHead({
  numSelected,
  rowCount,
  onSortingChange,
  onSelectAllClick,
  columns,
  order,
  orderBy,
  theme
}: EnhanceTableType) {

  const createSortHandler = (columnDir: String, orderColumn: String) => {
    onSortingChange(columnDir, orderColumn);
  };

  const indeterminate: boolean = numSelected > 0 && numSelected < rowCount;

  const checked = rowCount > 0 && numSelected === rowCount
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: themes[theme].sidebar.color }}
            indeterminate={indeterminate}
            checked={checked}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {columns.map((column: any, key: any) => (
          // @ts-ignore
          <TableCell
            key={key}
            style={{ color: themes[theme].sidebar.color }}
            align={column.align ? column.align : 'left'}
            padding={column.padding ? column.padding : 'normal'}
            width={column.width ? column.width : 150}
            sortDirection={orderBy === column.name ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === column.name}
              direction={orderBy === column.name ? order : 'asc'}
              className={`${theme}-sort-label`}
              onClick={() => createSortHandler(orderBy === column.name ? order : 'desc', column.name)}
              style={{ fontWeight: "bold", color: orderBy === column.name && theme === 'dark' ? '#ccc' : themes[theme].sidebar.color }}
            >
              {column.label}
              {orderBy === column.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

interface EnhancedTableToolbarType {
  numSelected: number;
  title?: String;
  theme: themeType;
  filterComponent?: React.ReactNode
  onSelectedDelete: (id: number[]) => void;
  selected: Array<number>;
}
function EnhancedTableToolbar({ numSelected, title, onSelectedDelete, selected, theme, filterComponent }: EnhancedTableToolbarType) {
  const [showFilter, setShowFilter] = useState(false)
  
  useEffect(() => {
    window.addEventListener('click', handleClickOutside, false)
    return () => window.removeEventListener('click', handleClickOutside, false)
  }, [])

  const handleClickOutside = (e:any) => {
    if(!e.target.closest('.filter-container') && !e.target.closest('.filter-btn')){
      setShowFilter(false)
    }
  }
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{
        background: themes[theme].sidebar.backgroundColor,
        zIndex: 1,
        borderBottom: '2px solid #1c4f84',
        position: 'relative'
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{color: themes[theme].sidebar.color}}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{color: themes[theme].sidebar.color, fontWeight: "700"}}
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete" onClick={() => onSelectedDelete(selected)}>
          <IconButton style={{color: themes[theme].sidebar.color}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        filterComponent && <Tooltip title="Filter list">
          <IconButton className='filter-btn' style={{color: themes[theme].sidebar.color, zIndex: 99}} onClick={() => setShowFilter(!showFilter)}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      {
        showFilter && filterComponent
      }
    </Toolbar>
  );
}

type ListTypes = {
  initialOrder: String,
  initialDir: Direction,
  rowPerPage: number,
  enableSelection?: Boolean,
  enableSorting?: Boolean
  title: String;
  loading?: Boolean;
  rows: any,
  filterComponent?: React.ReactNode
  columns: any
  onSortingChange: (x: String, y: String) => void
  onSelected?: () => void
  onSelectedDelete: (id: number[]) => void
  handleRowClick?: (row:any) => void
};

const List: React.FC<ListTypes> = ({
  rows,
  columns,
  rowPerPage = 50,
  onSortingChange,
  initialDir,
  initialOrder,
  title,
  filterComponent,
  onSelectedDelete,
  loading,
  handleRowClick
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowPerPage);
  const { theme } = useSelector((state: RootState) => state.sidebar);
  const handleSelect = (row: any) => {
    // @ts-ignore
    const selectedIndex = selected.findIndex(newRow => newRow.id === row.id);
    if (selectedIndex < 0) {
      // @ts-ignore
      setSelected([...selected, row])
    } else {
      selected.splice(selectedIndex, 1);
      setSelected([...selected]);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row: any) => {
    // @ts-ignore
    const index = selected.findIndex(newRow => newRow.id === row.id);
    return index > -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      !loading && rows && rows.length ? rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ) : [],
    [page, rowsPerPage, loading, rows],
  );

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelected = visibleRows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Box>
      <Paper style={{ backgroundColor: themes[theme].header.backgroundColor }}>
        <EnhancedTableToolbar
          selected={selected}
          numSelected={selected.length}
          title={title}
          onSelectedDelete={onSelectedDelete}
          theme={theme}
          filterComponent={filterComponent}
        />
        <TableContainer style={{ height: '63vh', overflow: 'auto', paddingBottom: 5, }}>
          <Table
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={initialDir}
              orderBy={initialOrder}
              onSelectAllClick={handleSelectAll}
              onSortingChange={onSortingChange}
              rowCount={visibleRows.length}
              columns={columns}
              theme={theme}
            />
            <TableBody style={{ height: '90%' }}>
              {visibleRows.map((row: any, index: number) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                    className={theme}
                    onClick={() => handleRowClick ? handleRowClick(row): undefined}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        style={{ color: themes[theme].sidebar.color }}
                        checked={isItemSelected}
                        onClick={() => handleSelect(row)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    {
                      columns.map((column: any, i: number) =>
                        <TableCell
                          component="th"
                          style={{ color: themes[theme].sidebar.color }}
                          id={labelId}
                          scope="row"
                          key={i}
                          align={i === 0 ? "left" : "center"}
                        >
                          {row[column.name]}
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 250]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ color: themes[theme].sidebar.color }}
          className={`${theme}-pagination`}
        />
      </Paper>
    </Box>
  );
}

export default memo(List);