import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import GetBusinessRole from "../../../graphql/queries/GetBusinessRoles";
import UpdateBusinessRole from "../../../graphql/mutations/UpdateBusinessRole";
import { notification, themes } from "../../../service/index";


const schema = yup
    .object({
        role: yup.string().required("Please Enter UserType"),
    })
    .required()

interface Props {
    id: number | null;
    refetch: () => void;
    theme: any;
    handleClose: () => void;
}
const EditBusinessRole: React.FC<Props> = ({ id, handleClose, refetch, theme }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({ resolver: yupResolver(schema) });
    const [updateUserType] = useMutation(UpdateBusinessRole);

    const { data, loading, error } = useQuery(GetBusinessRole, {
        fetchPolicy: "cache-and-network",
        variables: {
            where: JSON.stringify({
                id
            })
        }
    });
    useEffect(() => {
        if(!loading && error){
            return console.log("error", error);
        }
        if (!loading && data && data.userTypes) {
            const [businessRole] = data.userTypes;
            setValue('role', businessRole?.role);
        }
    }, [loading, data, error, setValue]);

    const onFormSubmit = (v: any) => {
        updateUserType({
            variables: {
                input: v,
                id: Number(id)
            },
            onCompleted: (data) => {
                if(data && data.updateUserType && data.updateUserType.success){
                    notification({message: 'Business Role updated successfully!'})
                    refetch()
                    handleClose()
                }
            },
            onError: (err) => {
                notification({ message: err.message, type: 'danger' })
            }
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>Update Business Role</Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="User Type"
                    placeholder="Enter User Type"
                    customId="role"
                    type="text"
                    register={register}
                    errors={errors?.role?.message}
                />
                <SubmitButton label="Update Role" />
            </form>
        </div>
    )
}
export default EditBusinessRole;