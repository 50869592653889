import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "./SideBar";

interface Props{
    auth: boolean;
    Component: React.ReactNode;
}
const PrivateRoute:React.FC<Props> = ({ auth, Component }) => {
    return (
        <>
            {
                auth ? <Sidebar compoment={Component} />
                    : <Navigate to={'/login'} />
            }
        </>
    )
}

export default PrivateRoute;