import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Login from "./pages/Login";
import HomePage from "./pages/HomePage";
import PrivateRoute from "./components/PrivateRoutes";
import UserDetails from "./pages/UserDetails";
import Users from "./pages/Users";

//list import
import OrganizationAddress from "./pages/OrganizationAddress";
import UserAddress from "./pages/UserAddress";
import Organization from "./pages/Organization";
import BusinessRole from "./pages/BusinessRole";
import UserBusinessRole from "./pages/UserBusinessRole";
import PageNotFound from "./pages/404";
import Unauthorized from "./pages/Unauthorized";
import { useAllowedRole } from "./Hooks/useAllowedRole";
import Notifications from "./pages/Notifications";
import Leaves from "./pages/Leaves";
import Attendance from "./pages/Attendance";
import AttendanceDetails from "./pages/AttendanceDetails";
import Client from "./pages/Client";

interface RouterProps{
    auth: boolean
}
const Routers:React.FC<RouterProps> = ({ auth }) => {
    const allowedRole = useAllowedRole();
    const isSystem = allowedRole === 'system'
    const isSuper = allowedRole === 'super' || allowedRole === 'staff' || isSystem
    return (
        <Router>
            <Routes>
                <Route path="/" element={<PrivateRoute auth={auth} Component={<HomePage />}/>} />
                <Route path="/login" element={<Login />} />
                <Route path="/visits/:organization" element={<Client />} />
                <Route path="/users" element={<PrivateRoute auth={auth} Component={isSuper ? <Users /> : <Unauthorized />} />} />
                <Route path="/users/:id" element={<PrivateRoute auth={auth} Component={isSuper ? <UserDetails /> : <Unauthorized />} />} />
                <Route path="/business-role" element={<PrivateRoute auth={auth} Component={<BusinessRole />} />} />
                <Route path="/address" element={<PrivateRoute auth={auth} Component={isSystem ? <OrganizationAddress /> : <Unauthorized />} />} />
                <Route path="/user-address" element={<PrivateRoute auth={auth} Component={isSuper ? <UserAddress /> : <Unauthorized />} />} />
                <Route path="/organization" element={<PrivateRoute auth={auth} Component={isSystem ? <Organization/> : <Unauthorized />}/>} />
                <Route path="/permission" element={<PrivateRoute auth={auth} Component={isSuper ? <UserBusinessRole /> : <Unauthorized />}/>} />
                <Route path="/attendance" element={<PrivateRoute auth={auth} Component={isSuper ? <Attendance /> : <Unauthorized />}/>} />
                <Route path="/attendance/:userId" element={<PrivateRoute auth={auth} Component={isSuper ? <AttendanceDetails /> : <Unauthorized />}/>} />
                <Route path="/leaves" element={<PrivateRoute auth={auth} Component={isSuper ? <Leaves /> : <Unauthorized />}/>} />
                <Route path="/notification" element={<PrivateRoute auth={auth} Component={isSuper ? <Notifications /> : <Unauthorized />}/>} />
                <Route path="/unauthorized" element={<PrivateRoute auth={auth} Component={<Unauthorized />}/>} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default Routers;
