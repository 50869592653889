import React from 'react'

import UsersList from '../components/lists/UsersList'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers/store'

const Users:React.FC = () => {
  const { user } = useSelector((state:RootState) => state.app);
  return (
    <UsersList organization={user?.organization !== 1 ? user?.organization : null} />
  )
}

export default Users