import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import UpdateUser from "../../../graphql/mutations/UpdateUser";
import GetUsers from "../../../graphql/queries/GetUsers";
import { notification, themes } from "../../../service";
import { RootState } from "../../../reducers/store";

interface initValueType {
    firstName: string;
    lastName: string;
    mobile: string;
    bod?: Date;
    joiningDate?: Date;
}

interface Props {
    id: number | null;
    handleClose: () => void
}

const schema = yup
    .object({
        firstName: yup.string().required("Please Enter First Name"),
        lastName: yup.string().required("Please Enter Last Name"),
        mobile: yup.string().required("Please enter mobile number"),
    })
    .required()

const EditUserForm: React.FC<Props> = ({ id, handleClose }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({ resolver: yupResolver(schema) });

    const { user } = useSelector((state: RootState) => state.app)
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [dob, setDob] = useState<Date | null>();
    const [joiningDate, setJoiningDate] = useState<Date | null>(new Date());
    const [updateUser] = useMutation(UpdateUser);

    const { data, loading } = useQuery(GetUsers, {
        variables: {
            where: JSON.stringify({
                userId: id
            })
        }
    });

    useEffect(() => {
        console.log('data', data);

        if (!loading && data && data.users) {
            const [user] = data.users
            const { firstName, lastName, mobile, dob, joiningDate } = user;
            setValue('firstName', firstName);
            setValue('lastName', lastName);
            setValue('mobile', mobile);
            if (dob)
                setDob(new Date(dob))
            if (joiningDate)
                setJoiningDate(new Date(joiningDate))
        }
    }, [loading, data, setValue]);


    const onFormSubmit = (v: initValueType) => {
        updateUser({
            variables: {
                input: {
                    ...v,
                    dob: dob ? moment(dob).format("YYYY-MM-DD") : undefined,
                    joiningDate: joiningDate ? moment(joiningDate).format("YYYY-MM-DD") : undefined,
                    organization: user?.organization
                },
                userId: id
            },
            refetchQueries: ['GetUsers']
        }).then(({ data }) => {
            if (data && data.updateUser) {
                notification({ message: 'User Updated successfully!' })
                handleClose();
            }
        }).catch(err => {
            notification({ message: err.message, type: 'danger' })
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>Edit User Form</Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="First Name"
                    placeholder="Enter First Name"
                    customId="firstName"
                    type="text"
                    register={register}
                    errors={errors?.firstName?.message}
                />
                <TextField
                    label="Last Name"
                    placeholder="Enter Last Name"
                    customId="lastName"
                    type="text"
                    register={register}
                    errors={errors?.lastName?.message}
                />
                <TextField
                    label="Mobile Number"
                    placeholder="Enter mobile number"
                    customId="mobile"
                    type="text"
                    register={register}
                    errors={errors?.mobile?.message}
                />
                <div style={{ marginBottom: 14 }}>
                    <label htmlFor="dob" className="text-label">
                        Date of Birth
                    </label>
                    <DatePicker selected={dob} onChange={(date) => setDob(date)} />
                </div>
                <div style={{ marginBottom: 14 }}>
                    <label htmlFor="joiningDate" className="text-label">
                        Joining Date
                    </label>
                    <DatePicker selected={joiningDate || new Date()} onChange={(date) => setJoiningDate(date)} />
                </div>
                <SubmitButton label="Edit User" />
            </form>
        </div>
    )
}

export default EditUserForm;