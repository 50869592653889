import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import TextField from "../components/common/TextField";
import SubmitButton from "../components/common/SubmitButtom";
import CustomLink from "../components/common/CustomLink";
import LoginMutation from "../graphql/mutations/Login";
import { doLogin } from "../reducers/app";
import { notification } from "../service";

interface loginType {
    username: string | undefined
    password: string
}

const schema = yup
    .object({
        username: yup.string().required("Please Enter Username"),
        password: yup.string()
            .required("Please enter your password").matches(
                /^.*(?=.{7,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 7 characters, one uppercase, one number and one special case character"
            )
    })
    .required()

const Login: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login] = useMutation(LoginMutation);

    const onFormSubmit = (v: loginType) => {
        let { username } = v;
        username = username?.toLowerCase()
        let email
        if(username?.includes("@")){
            email = username;
            username = undefined
        }
        login({
            variables: {
                input: {password: v.password, email, username}
            },
            onCompleted: ({login}) => {
                console.log('Data', login);
                if (login && login.result) {
                    const { result: { token, user } } = login;
                    if (token && user) {
                        notification({ title: 'Login Success', message: 'Login Successfully!' })
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        dispatch(doLogin({ token, user }));
                        if(user.organization)
                            navigate("/");
                        else
                            navigate("/unauthorized")
                    }
                }
            },
            onError: (err) => {
                notification({ message: err.message, type: 'danger' })
            }
        })
    }

    useEffect(() => {
        console.log('Inner width', window.innerWidth);
        if(window.innerWidth <= 500){
            navigate('/404')
        }
    }, [navigate])

    return (
        <div className="login-signup-bg pt-md-5 pt-2">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={8} md={6} lg={4}>
                    <div className="login-form-wrapper">
                        <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: '#1346a3' }}>
                            Login Form
                        </Typography>
                        <form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                            <TextField
                                label="Username"
                                placeholder="Please Enter Your Username"
                                customId="username"
                                register={register}
                                errors={errors?.username?.message}
                            />
                            <TextField
                                label="Password"
                                placeholder="Enter Password"
                                customId="password"
                                register={register}
                                type="password"
                                errors={errors?.password?.message}
                            />
                            <CustomLink link="/forget-pass" label="Forget Password" text="Forgot your password?" />
                            <SubmitButton label="Login" />
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Login