import { gql } from "@apollo/client";

export default gql`
query GetUserAttendance($where: JSONString, $limit: Int, $order: String, $offset: Int){
    userAttendance(where: $where, limit: $limit, order:$order, offset: $offset){
        userId
        firstName
        lastName
        present
        absent
    }
}
`