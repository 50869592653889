import React from "react";
import { Link } from "react-router-dom";

interface Props {
    link: any
    label: String
    text?: String
}

const CustomLink:React.FC<Props> = ({ link, label, text='' }) => {
    return (
        <div style={{ padding: '10px 0px', textAlign: 'right'}}>
            <span>{text}<Link to={link} className="ms-1">{label}</Link></span>
        </div>
    )
}

export default CustomLink;