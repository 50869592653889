import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";
import moment from "moment";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import { notification, themes } from "../../../service";
import CreateUserMutation from "../../../graphql/mutations/CreateUser";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store";
import CheckBoxComponent from "../../common/Checkbox";
import AddressForm from "../../common/AddressForm"

interface initValueType {
  username: string
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword?: string
  dob?: Date
  joiningDate?: Date
  mobile?: string
}
const schema = yup
  .object({
    username: yup.string().required("Please Enter Username"),
    firstName: yup.string().required("Please Enter First Name"),
    lastName: yup.string().required("Please Enter Last Name"),
    email: yup.string().email().required("Please Enter Email"),
    mobile: yup.string(),
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Passwords don't match."),
    isEmployee: yup.bool().required("This Field is required!"),
    isStaff: yup.bool().required("This Field is required!"),
    isSuper: yup.bool().required("This Field is required!"),
    dob: yup.date().nullable().when('isEmployee', (isEmployee: any) => (
      !!isEmployee[0] ? yup.date().required("Date of Birth is Required!") : yup.date().nullable()
    )),
    joiningDate: yup.date().nullable().when('isEmployee', (isEmployee: any) => (
      !!isEmployee[0] ? yup.date().required("Joining Date is Required!") : yup.date().nullable()
    )),
    street: yup.string().nullable().when('isEmployee', (isEmployee: any) =>
      !!isEmployee[0] ? yup.string().required("This field is required!") : yup.string().nullable()
    ),
    city: yup.string().nullable().when('isEmployee', (isEmployee: any) =>
      !!isEmployee[0] ? yup.string().required("This field is required!") : yup.string().nullable()
    ),
    postalCode: yup.string().nullable().when('isEmployee', (isEmployee: any) =>
      !!isEmployee[0] ? yup.string().required("This field is required!") : yup.string().nullable()
    ),
    state: yup.string().nullable().when('isEmployee', (isEmployee: any) =>
      !!isEmployee[0] ? yup.string().required("This field is required!") : yup.string().nullable()
    ),
    country: yup.string().nullable().when('isEmployee', (isEmployee: any) =>
      !!isEmployee[0] ? yup.string().required("This field is required!") : yup.string().nullable()
    ),
  })
  .required();

interface Props {
  handleClose: () => void
}
const SignUp: React.FC<Props> = ({ handleClose }) => {
  const [dob, setDob] = useState<Date | null>(null);
  const [joiningDate, setJoiningDate] = useState<Date | null>(new Date());
  const { user } = useSelector((state: RootState) => state.app)
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const [isEmployee, setEmployee] = useState<boolean>(!!user?.isStaff);
  const [isStaff, setStaff] = useState<boolean>(false);
  const [isSuper, setSuper] = useState<boolean>(false);
  const [isNext, setNext] = useState<boolean>(false);

  const initialValue: initValueType = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    confirmPassword: "",
    dob: undefined,
    joiningDate: undefined,
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValue,
    },
  });
  console.log("errors", errors);
  
  const [createUser] = useMutation(CreateUserMutation);

  const onFormSubmit = (v: any) => {
    if (v.password !== v.confirmPassword) {
      return;
    }
    delete v.confirmPassword;
    const { username, firstName, lastName, email, password, mobile, dob, joiningDate, isSuper, isEmployee, isStaff, ...address } = v;
    createUser({
      variables: {
        input: {
          username,
          firstName,
          lastName,
          email,
          password,
          mobile,
          dob: dob ? moment(dob).format('YYYY-MM-DD') : undefined,
          joiningDate: joiningDate ? moment(joiningDate).format("YYYY-MM-DD") : undefined,
          organization: user?.organization,
          isEmployee,
          isSuperuser: isSuper,
          address: isSuper ? undefined : address,
          isStaff
        },
      },
      onCompleted: (data) => {
        if (data && data.createUser) {
          handleClose()
          notification({ message: "User Created Successfully!" });
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetUsers"],
    })
  };

  useEffect(() => {
    if(dob){
      setValue('dob', dob)
      clearErrors('dob')
    }else{
      setValue('dob', undefined)
    }
    if(joiningDate){
      setValue('joiningDate', joiningDate)
      clearErrors('joiningDate')
    }else{
      setValue('joiningDate', undefined)
    }
  }, [dob, joiningDate, setValue, clearErrors])

  const handleNextCLick = () => {
    if (getValues('username') && getValues('firstName') && getValues('lastName') && getValues('email') && getValues('password') && getValues('confirmPassword')) {
      setNext(true)
      clearErrors()
    }
  }

  const maxDob = new Date();
  const newYear = maxDob.getFullYear() - 18;
  maxDob.setFullYear(newYear);

  useEffect(() => {
    setValue('isEmployee', isEmployee)
    if(isEmployee)
      setSuper(false)
  }, [isEmployee, setValue, setSuper])

  useEffect(() => {
    setValue('isSuper', isSuper)
    if (isSuper){
      setEmployee(false)
      setStaff(false)
    }
  }, [isSuper, setValue, setStaff, setEmployee])

  useEffect(() => {
    setValue('isStaff', isStaff)
    if(isStaff){
      setEmployee(true)
      setSuper(false)
    }
  }, [isStaff, setValue, setSuper, setEmployee])
  const disabled = !isEmployee && !isSuper
  return (
    <div className="login-signup">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <div className="form-wrapper">
            <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: themes[theme].sidebar.color,
              }}
            >
              Add New User
            </Typography>
            <form className={`form-${theme} signup-form`} onSubmit={handleSubmit(onFormSubmit)}>
              {!isNext && <><TextField
                label="Username"
                placeholder="Please Enter Username"
                customId="username"
                type="text"
                register={register}
                errors={errors?.username?.message}
              />
                <TextField
                  label="First Name"
                  placeholder="Enter First Name"
                  customId="firstName"
                  type="text"
                  register={register}
                  errors={errors?.firstName?.message}
                />
                <TextField
                  label="Last Name"
                  placeholder="Enter Last Name"
                  customId="lastName"
                  type="text"
                  register={register}
                  errors={errors?.lastName?.message}
                />
                <TextField
                  label="Mobile Number"
                  placeholder="Enter Mobile Number"
                  customId="mobile"
                  type="text"
                  register={register}
                  errors={errors?.mobile?.message}
                />
                <TextField
                  label="Email"
                  placeholder="Enter Your Email"
                  customId="email"
                  type="email"
                  register={register}
                  errors={errors?.email?.message}
                />
                <TextField
                  label="Password"
                  placeholder="Enter Password"
                  customId="password"
                  type="password"
                  register={register}
                  errors={errors?.password?.message}
                />
                <TextField
                  label="Confirm Password"
                  placeholder="Enter Confirm Password"
                  customId="confirmPassword"
                  type="password"
                  register={register}
                  errors={errors?.confirmPassword?.message}
                />
                {isEmployee && <>
                  <div style={{ marginBottom: 14 }}>
                    <label htmlFor="dob" className="text-label">
                      Date of Birth
                    </label>
                    <DatePicker
                      selected={dob}
                      onChange={(date) => setDob(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={maxDob}
                    />
                    <p style={{ color: 'red', marginTop: 5 }}>{errors.dob?.message}</p>
                  </div>
                  <div style={{ marginBottom: 14 }}>
                    <label htmlFor="joiningDate" className="text-label">
                      Joining Date
                    </label>
                    <DatePicker
                      selected={joiningDate || new Date()}
                      onChange={(date) => setJoiningDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      calendarClassName="joining-date"
                      maxDate={new Date()}
                    />
                    <p style={{ color: 'red', marginTop: 5 }}>{errors.joiningDate?.message}</p>
                  </div>
                </>}
                <CheckBoxComponent checked={isEmployee} onChange={(e: any) => setEmployee(e.target.checked)} label="Is Employee" />
                {user?.isSuperuser && <CheckBoxComponent checked={isStaff} onChange={(e: any) => setStaff(e.target.checked)} label="Is Admin" />}
                {user?.isSuperuser && user.organization === 1 && <CheckBoxComponent checked={isSuper} onChange={(e: any) => setSuper(e.target.checked)} label="Is Super User" />}
              </>
              }
              {isNext && isEmployee && <AddressForm errors={errors} register={register} setValue={setValue} isUser />}
              <div className="d-flex justify-content-between align-items-center mt-2">
                {isNext ? <button
                  className="signup-btn"
                  onClick={() => setNext(false)}>
                  Back
                </button> : <span></span>}
                {
                  isEmployee && !isNext ? <button
                    className="signup-btn"
                    onClick={handleNextCLick}>
                    Next
                  </button> : <SubmitButton disabled={disabled} label="Add User" />
                }
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
