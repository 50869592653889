import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Typography, Badge, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  BarChart,
  ShoppingCart,
  CalendarMonth,
  Book,
  HeartBroken,
  People,
  ContactMail,
  BusinessCenter,
  Home,
  EnhancedEncryption,
  Notifications,
  AccessTime,
  Store,
  AddHome,
  AirlineSeatReclineNormal
} from "@mui/icons-material";

import { SidebarHeader } from "./SideBarHeader";
import Header from "./Header";
import { hexToRgba, themes } from "../service";
import { setCollapse, setTheme } from "../reducers/sidebar";
import type { RootState } from "../reducers/store";
import Unauthorized from "../pages/Unauthorized";
import { useAllowedRole } from "../Hooks/useAllowedRole";

interface sublinkType{
  link: string;
  label: string;
}

interface routeType{
  link?: string;
  icon: React.ReactNode;
  label: string;
  allowed?: string[];
  subLink?: sublinkType[];
  suffix?: React.ReactNode
}
const routes:routeType[] = [
  {
    link: "/",
    icon: <Home />,
    label: "Dashboard",
    allowed: ['system', 'super', 'staff', 'guest']
  },
  {
    link: "/users",
    icon: <People />,
    label: "Users Management",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/organization",
    icon: <Store />,
    label: "Organization Management",
    allowed: ['system']
  },
  {
    link: "/address",
    icon: <AddHome />,
    label: "Organization Address",
    allowed: ['system']
  },
  {
    link: "/user-address",
    icon: <ContactMail />,
    label: "User Address",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/business-role",
    icon: <BusinessCenter />,
    label: "Business Role",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/permission",
    icon: <EnhancedEncryption />,
    label: "Permission Management",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/attendance",
    icon: <AccessTime />,
    label: "Attendance Management",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/leaves",
    icon: <AirlineSeatReclineNormal />,
    label: "Leaves Management",
    allowed: ['system', 'super', 'staff']
  },
  {
    link: "/notification",
    icon: <Notifications />,
    label: "Manage Notification",
    allowed: ['system', 'super', 'staff']
  },
  {
    label: "Charts",
    icon: <BarChart />,
    suffix: (
      // @ts-ignore
      <Badge variant="danger" shape="circle">
        6
      </Badge>
    ),
    subLink: [
      {
        link: "/charts/pie-charts",
        label: "Pie charts",
      },
      {
        link: "/charts/line-charts",
        label: "Line charts",
      },
      {
        link: "/charts/bar-charts",
        label: "Bar charts",
      },
      {
        link: "/charts/group-charts",
        label: "Group charts",
      },
    ],
  },
];

interface Props {
  compoment: React.ReactNode
}
type themeType = 'dark' | 'light'

const SidebarPage: React.FC<Props> = ({ compoment }) => {
  const { collapsed, theme } = useSelector((state: RootState) => state.sidebar);
  const { user } = useSelector((state: RootState) => state.app);
  const isAllowed = useAllowedRole();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggled, setToggled] = React.useState(false);
  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }: any) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : "transparent",
    }),
    button: {
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }: any) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const handleThemeChange = (val:themeType) => {
    dispatch(setTheme(val))
    localStorage.setItem('theme', val)
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            style={{ marginBottom: "24px", marginTop: "16px" }}
            theme={theme}
            setTheme={handleThemeChange}
            collapsed={collapsed}
          />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: collapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}
              >
                General
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              {routes.map((route, key) =>
               route.allowed && route.allowed.includes(isAllowed) ? route.subLink ? (
                  <SubMenu key={key} label={route.label} icon={route.icon} suffix={route.suffix || null}>
                    {route.subLink.map((subRoute, key2) => (
                      <MenuItem
                        active={location.pathname.includes(subRoute.link)}
                        key={key2}
                        component={<Link to={subRoute.link} />}
                      >
                        {subRoute.label}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    active={route.link !== "/" ? location.pathname.includes(route.link || "") : location.pathname === route.link}
                    key={key}
                    component={<Link to={route.link || ""} />}
                    icon={route.icon}
                  >
                    {route.label}
                  </MenuItem>
                ) : <></>
              )}
              <SubMenu label="E-commerce" icon={<ShoppingCart />}>
                <MenuItem> Product</MenuItem>
                <MenuItem> Orders</MenuItem>
                <MenuItem> Credit card</MenuItem>
              </SubMenu>
            </Menu>

            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: collapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}
              >
                Extra
              </Typography>
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<CalendarMonth />}
                suffix={<Badge>New</Badge>}
              >
                Calendar
              </MenuItem>
              <MenuItem icon={<Book />}>Documentation</MenuItem>
              <MenuItem disabled icon={<HeartBroken />}>
                Examples
              </MenuItem>
            </Menu>
          </div>
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              flexDirection: collapsed ? "column" : "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {!collapsed && <Typography>Collapse</Typography>}
            <Switch
              id="collapse"
              checked={collapsed}
              onChange={() => dispatch(setCollapse(!collapsed))}
            />
          </div>
        </div>
      </Sidebar>
      <div
        style={{
          width: collapsed ? "calc(100% - 80px)" : "calc(100% - 250px)",
          transition: "0.3s",
          transitionTimingFunction: "ease-out",
          backgroundColor: themes[theme].sidebar.backgroundColor,
        }}
      >
        <Header />
        <div className="content-container">
          {user?.organization ? compoment : <Unauthorized />}
        </div>
      </div>
    </div>
  );
};

export default SidebarPage;
