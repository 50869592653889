import { gql } from "@apollo/client";

export default gql`
query GetOrganizations($email: String, $id: ID, $nameContains: String, $name: String){
    organizations(orgEmail: $email, id: $id, name_Icontains: $nameContains, name: $name) {
        edges{
          node{
            id
            orgId
            name
            orgEmail
            orgPhone
            logo
            qrCode
            slug
          }
        }
    }
}

`