import React from "react";
import { Grid, Button } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { themes } from "../../service";

interface Props{
  show?: boolean;
  setShow?:any;
  showButton?: boolean
  setSearch: (e:any) => void;
}
const AddButton:React.FC<Props> = ({ show, setShow, setSearch, showButton = true }) => {
  const { theme } = useSelector((state: RootState) => state.sidebar)

  return (
    <div>
      <Grid
        className="d-flex justify-content-end mt-4"
        style={{
          height: 60,
          padding: '11px 20px',
          backgroundColor: themes[theme].sidebar.backgroundColor
        }}
      >
        <SearchBar setSearch={setSearch} theme={theme} />
          {showButton && <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setShow(!show);
            }}
          >
            <PersonAddAlt1Icon />
          </Button>}
      </Grid>
    </div>
  );
};

export default AddButton;
