import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useMutation } from "@apollo/client";

import { downloadImage, notification, themes } from "../../service/index";
import GenerateQrCode from "../../graphql/mutations/GenerateQrCode";

interface Props {
    id: number;
    row: any;
    theme: any;
}
const QrCode: React.FC<Props> = ({ id, row, theme }) => {
    const [generateQrCode] = useMutation(GenerateQrCode);

    const handleCreate = () => {
        generateQrCode({
            variables: {
                organization_id: id,
            },
            onCompleted: (data) => {
                if (data && data.createQrcode && data.createQrcode.id) {
                    notification({
                        message: "QR code created succefully!",
                        title: "Successfull",
                        type: "success",
                    });
                }
            },
            onError: (err) => {
                notification({
                    message: err.message,
                    title: "Failed",
                    type: "danger",
                });
            },
            refetchQueries: ["GetOrganizations"],
        })
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            {row.qrCode ? (
                <div className="d-flex justify-content-center items-center w-50">
                    <img
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${row.qrCode}`}
                        width={"50%"}
                        style={{ borderRadius: "10px", display: "inline-block" }}
                        alt="logo"
                        onError={handleCreate}
                    />
                    <button 
                        className="bg-transparent border-0 ms-2" 
                        onClick={() => downloadImage(`${process.env.REACT_APP_BACKEND_BASE_URL}/${row.qrCode}`)}
                    >
                        <DownloadIcon fontSize="large" style={{color: themes[theme].sidebar.color}} />
                    </button>
                </div>
            ) : (
                <button className="generate-qr-btn" onClick={handleCreate}>
                    Generate
                </button>
            )}
        </div>
    );
};

export default QrCode;
