import React from 'react';
import OrganizationList from '../components/lists/OrganizationList';

const Organization: React.FC = () => {
  return (
    <div style={{ marginTop: "2px" }}>
      <OrganizationList />
    </div>
  )
}
export default Organization;