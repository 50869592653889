import React, { useState } from 'react'
import { Button } from '@mui/material';
import { useSelector } from 'react-redux'

import { RootState } from '../reducers/store'
import { themes } from '../service';
import AddOrganization from '../components/forms/organizations/Organization';
import AbstractModal from '../components/AbstractModal';

const Unauthorized: React.FC = () => {
    const { theme } = useSelector((state: RootState) => state.sidebar);
    const { user } = useSelector((state: RootState) => state.app);
    const [show, setShow] = useState<boolean>(false)

    return (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <div style={{ color: themes[theme].sidebar.color }}>
                <h2>Oops...</h2>
                <p>Sorry You Cannot access this page!</p>
                {user?.isSuperuser && !user?.organization && <p>You don't have organization!</p>}
                {user?.isSuperuser && !user?.organization && <p style={{fontSize: 18, fontWeight: '600'}}>Please create Organization to access all the pages</p>}
                {user?.isSuperuser && !user?.organization && <Button color='primary' onClick={() => setShow(true)} variant='contained'>Create Organization</Button>}
            </div>
            <AbstractModal show={show} handleClose={() => setShow(false)} Component={<AddOrganization handleClose={() => setShow(false)} />} />
        </div>
    )
}

export default Unauthorized